import { ETariffTypeTitle } from "@dltru/dfa-models"
import { FC, useContext, useEffect, useState } from "react"
import { ExclamationCircle } from "../../../Icons"
import { Tooltip } from "../../../Tooltip"
import { TariffRowContext } from "./TariffRowContext"

export interface IErrorRenderProps {
    names: {
        amountShow: string,
        amountShowType: string,
        maximiumShow: string,
        maximiumShowType: string,
        minimumShow: string,
        minimumShowType: string
    },
    feeCode: string
    showError: boolean
}

export const ErrorType1 = (feeCode: string) => `Для комиссии ${feeCode} необходимо указать минимальную или (и) максимальную комиссию`
export const ErrorType2 = (feeCode: string) => `Внимание! Для комиссии ${feeCode} не указана расчетная комиссия. Если вы не укажите расчетную комиссию, то по умолчанию будет списываться максимальная сумма комиссии`

const getValuesByNames = (names: Record<string, string>, values: Record<string, unknown>) => {
    return Object.entries(names).reduce((acc, curr) => {
        acc[curr[0]] = values[curr[1]]

        return acc;

    }, {} as unknown as Record<string, unknown>)
}

export const ErrorRender: FC<IErrorRenderProps> = ({ names, feeCode, showError }) => {
    const { values } = useContext(TariffRowContext)
    const [error, setError] = useState('')

    const errorType1 = ErrorType1(feeCode)
    const errorType2 = ErrorType2(feeCode)

    useEffect(() => {
        console.log('*** error Uf ', showError)
        if (showError) {
            setError(errorType1)
        }
    }, [showError])

    useEffect(() => {


        if (values && names) {
            const fields = getValuesByNames(names, values)
            // console.log('*** error render', fields)

            if (fields.amountShow && fields.amountShowType === ETariffTypeTitle.Percent) {
                let errorMessage = errorType1
                if (fields.maximiumShow || fields.minimumShow) {
                    errorMessage = ''
                }

                setError(errorMessage)
                return
            }

            if (!fields.amountShow && fields.maximiumShow && fields.minimumShow) {

                setError(errorType2)
                return
            }

        }

    }, [names, values])

    if (!error) {
        return null
    }

    return <Tooltip title={error}><ExclamationCircle /></Tooltip>
}